import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/npm-scripts",
  "date": "2016-06-20",
  "title": "NPM SCRIPTS",
  "author": "admin",
  "tags": ["development", "javascript", "node.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Task runners like Gulp or Grunt have been go to choice for the front end projects for last few years. Gulp being the latest between the two, and being the popular choice for last couple of years, one might wonder what’s wrong with it."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`But as your project gets large, your tasks runners get bloated as well, and sooner you will run into various issues.`}</p>
    <h2>{`Issues with Gulp and Grunt`}</h2>
    <p>{`Task runners like Gulp and Grunt have few common problems.`}</p>
    <ul>
      <li parentName="ul">{`Requires plugins for different tasks`}</li>
      <li parentName="ul">{`Too much or lack of documentation`}</li>
      <li parentName="ul">{`Difficult to debug`}</li>
    </ul>
    <h3>{`Requires plugins for different tasks`}</h3>
    <p>{`With Gulp and Grunt, tasks are run using plugins. Managing plugins becomes a nightmare when they are outdated. Some plugins may have bugs and you will have to wait for the plugin author to fix them before using it.`}</p>
    <p>{`When using NPM though, you are using the tools directly.`}</p>
    <h3>{`Too much or lack of Documentation`}</h3>
    <p>{`Plugins mean extra documentation. If you are using a plugin to use a tool, you would have to use both documentation for both the plugin and the tool.`}</p>
    <p>{`Usually the documentation for the tools are comprehensive than the plugins.`}</p>
    <h3>{`Difficult to Debug`}</h3>
    <p>{`Debugging in Gulp or Grunt can be a frustrating task as you are working with an extra level of abstraction.`}</p>
    <p>{`The cause for a bug could be one of the many from the below:`}</p>
    <ul>
      <li parentName="ul">{`Broken plugin`}</li>
      <li parentName="ul">{`Broken tool`}</li>
      <li parentName="ul">{`Bad configuration`}</li>
      <li parentName="ul">{`Incompatible version`}</li>
    </ul>
    <p>{`If you were using NPM instead, you completely eliminate the first issue on the list.`}</p>
    <h2>{`NPM Scripts`}</h2>
    <p>{`In the core of NPM, it has a the `}<inlineCode parentName="p">{`npm run-script`}</inlineCode>{` command, or the shorter version `}<inlineCode parentName="p">{`npm run`}</inlineCode>{`. This will access the `}<inlineCode parentName="p">{`script`}</inlineCode>{` property in the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`. It will pass a property inside the scripts object as its first parameter and execute the property’s value as command in the operating system’s default shell.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "project-name",
  "devDependencies": {
    "jshint": "latest",
    "mocha": "latest"
  },
  "scripts": {
    "lint": "jshint test/*.js",
    "test": "mocha test/"
  }
}
`}</code></pre>
    <p>{`The shell environment sets your `}<inlineCode parentName="p">{`node_modules/.bin`}</inlineCode>{` folder added to the `}<inlineCode parentName="p">{`PATH`}</inlineCode>{` so that the binaries installed can be run directly. This means you don’t have to rely on installing runnables globally.`}</p>
    <p>{`Running `}<inlineCode parentName="p">{`npm run`}</inlineCode>{` should give you the available options to run.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm run
`}</code></pre>
    <p>{`Result:`}</p>
    <pre><code parentName="pre" {...{}}>{`available via \`npm run-script\`:
lint
jshint **/*.js
`}</code></pre>
    <p>{`You also can retrieve extra metadata about your current environment by adding `}<inlineCode parentName="p">{`env`}</inlineCode>{` as a property to the scripts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"scripts": {
    "env": "env"
}
`}</code></pre>
    <h2>{`Shortcuts`}</h2>
    <p>{`NPM provides few very useful shortcuts for us.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`npm start`}</inlineCode>{` – runs the project on the given port for development`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`npm stop`}</inlineCode>{` – stops a running project in development`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`npm test`}</inlineCode>{` – standard interface within NPM for test.`}</li>
    </ul>
    <p>{`These are shortcuts for the command `}<inlineCode parentName="p">{`npm run`}</inlineCode>{`.`}</p>
    <h2>{`Pre and Post Hooks`}</h2>
    <p>{`NPM gives you a set of pre and post task in the means of `}<inlineCode parentName="p">{`pre-`}</inlineCode>{` and `}<inlineCode parentName="p">{`post-`}</inlineCode>{` hooks.`}</p>
    <p>{`For example for `}<inlineCode parentName="p">{`npm test`}</inlineCode>{` which is the shortcut for `}<inlineCode parentName="p">{`npm run test`}</inlineCode>{`, has pre and post scripts in \``}<inlineCode parentName="p">{`npm run pretest and `}</inlineCode>{`npm run posttest\`.`}</p>
    <Message type="info" title="Exit Code Sensitivity" content="Pre and post scripts will stop immediately if the exit code is non-zero." mdxType="Message" />
    <p>{`NPM runs pre and post hooks for few internal commands:`}</p>
    <ul>
      <li parentName="ul">{`install`}</li>
      <li parentName="ul">{`uninstall`}</li>
      <li parentName="ul">{`publish`}</li>
      <li parentName="ul">{`update`}</li>
    </ul>
    <p>{`Let’s look at an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "npm-scripts",
  "version": "1.0.0",
  "description": "NPM as a build tool demo",
  "main": "index.js",
  "scripts": {
    "env": "env",
    "lint": "jshint **/*.js",
    "test": "mocha test",
    "build": "babel src -d lib",
    "prepublish": "",
    "prebuild": "npm run lint",
    "pretest": ""
  },
  "keywords": [
    "NPM"
  ],
  "author": "Sean Amarasinghe",
  "license": "ISC",
  "devDependencies": {
    "babel-cli": "^6.0.0",
    "jshint": "^2.9.2",
    "mocha": "^2.5.3"
  }
}
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`Hopefully yhis article would motivate you to select NPM as the build tool for your next project. Or replace exisiting Gulp or Grunt tasks with NPM.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      